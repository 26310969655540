import './scss/FinancialAccounts.scss';

import { Button, Form, Pagination, Row, Table, TableColumnProps, Tooltip } from 'antd';
import { billTypes } from 'constants/dictionary/default/selectOptions';
import dayjs from 'dayjs';
import { useLocale } from 'hooks/useLocale';
import useLocalizedList from 'hooks/useLocalizedList';
import { UseSearch } from 'hooks/useSearch';
import { IAppState } from 'interfaces/app-state';
import { TransferAccounts } from 'layout/modals/finances/transferAccounts/TransferAccounts';
import React, { ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toogleShowTransferModal } from 'redux/finances/financialAccounts/actions';
import { IAccontsListState } from 'redux/finances/financialAccounts/reducer';
import { showModal as toogleShowPaymentModal } from 'redux/finances/payments/actions';
import { IPaymentsListState } from 'redux/finances/payments/reducer';

import { money } from '../../../helpers/list-helpers';
import AddPayment from '../../../layout/modals/finances/addPayment/AddPayment';
import { getAccountsDetailsPayments } from '../../../redux/finances/financialAccounts/actions';
import FinanceIcon from '../helpers/FinanceIcon';
import { getPaymentName } from '../payments/utils/utils';

const FinancialAccountsDetailsPayments = ({
  currentAccountId,
  currencies,
  loading,
  financialAccountId,
  clinicId,
  openedShift
}: any) => {
  const [addPaymentForm] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const localizedBillTypes = useLocalizedList(billTypes);
  const locale = useLocale('private.finances.payments');
  const financialLocale = useLocale('private.finances.financial-accounts');

  const {showModal} = useSelector<IAppState, IPaymentsListState>(({ payments }) => payments);
  const {showTransferModal} = useSelector<IAppState, IAccontsListState>(({ financialAccounts }) => financialAccounts);
  const selectedAccountPayments = useSelector<IAppState, any>(
    (state) => state.financialAccounts.selectedAccountPayments
  );

  const {
    handleChange,
    onPageChange,
    getResults
  } = UseSearch({
    action: getAccountsDetailsPayments,
    clinicId,
    defaultSortKey: 'created_at',
    defaultSortOrder: 'desc',
    additionalParam: financialAccountId
  });

  const redirectToPaymentDetails = (record: any) => {
    return {
      onClick: () => {
        navigate(`/clinic/${clinicId}/finances/payments/${record.id}`);
      }
    };
  };

  const columns: Array<TableColumnProps<any>> = [
    {
      key: 'payment_type',
      dataIndex: 'payment_type',
      title: '',
      sorter: (a, b) => (a.bill_type > b.bill_type ? 1 : -1),
      render: (bill_type, e) => <FinanceIcon type={bill_type} />,
      onCell: redirectToPaymentDetails
    },
    {
      key: 'name',
      title: locale.labels.name,
      sorter: (a, b) => (a.name > b.name ? 1 : -1),
      render: (e) => e?.bill ? getPaymentName(e.bill, locale, localizedBillTypes) : e?.name === 'internal_transfer' ? locale.labels.transferBetweenAccounts : e?.name,
      onCell: redirectToPaymentDetails
    },
    {
      key: 'created_at',
      dataIndex: 'created_at',
      title: locale.labels.date,
      sorter: (a, b) => (a.created_at > b.created_at ? 1 : -1),
      render: (created_at, e) =>
        created_at !== undefined ? dayjs(created_at).format('DD.MM.YYYY HH:mm') : '-',
      onCell: redirectToPaymentDetails
    },
    {
      key: 'amount',
      dataIndex: 'amount',
      title: locale.labels.sum,
      sorter: (a, b) => (a.amount > b.amount ? 1 : -1),
      render: (amount, e) => (amount ? `${money(amount)} ${currencies.uah}` : '-'),
      onCell: redirectToPaymentDetails
    },
    {
      key: 'notes',
      dataIndex: 'notes',
      title: locale.labels.notes,
      sorter: (a, b) => (a.notes > b.notes ? 1 : -1),
      render: (notes, e) => notes || '-',
      onCell: redirectToPaymentDetails
    },
    {
      key: 'description',
      dataIndex: 'description',
      title: locale.labels.description,
      sorter: (a, b) => (a.description > b.description ? 1 : -1),
      render: (description, e) => description || '-',
      onCell: redirectToPaymentDetails
    }
  ];

  const renderShowModalBtn = (icon: string, buttonTxt: string, action: (value: boolean) => any): ReactNode => {
    return <Tooltip title={!openedShift?.open ? locale.labels.paymentBlockedClosedShift : null}>
      <Button
        className={`icofont ${icon} open-modal-button`}
        type={'primary'}
        style={{ borderRadius: 30 }}
        disabled={!openedShift?.open}
        onClick={() => dispatch(action(true))}
      >
        <span className='open-modal-button__txt'>{buttonTxt}</span>
      </Button>
    </Tooltip>;
  };

  return (
    <>
      <h4>{locale.labels.payments}</h4>
      <Table
        className='accent-header bills-list-table'
        rowKey='id'
        dataSource={selectedAccountPayments?.data}
        columns={columns}
        pagination={false}
        loading={loading}
        onChange={handleChange}
      />
      <Row justify={'space-between'} style={{ marginTop: 20 }}>
        <Pagination
          defaultCurrent={1}
          pageSize={20}
          current={selectedAccountPayments?.metadata.page}
          total={selectedAccountPayments?.metadata.total}
          onChange={onPageChange}
          showSizeChanger={false}
          showLessItems
        />
        <Row style={{ gap: 20 }}>
          {!showModal && renderShowModalBtn('icofont-plus', locale.buttons.addPayment, toogleShowPaymentModal)}
          {!showTransferModal && renderShowModalBtn('icofont-loop', financialLocale.buttons.transferBetweenAccounts, toogleShowTransferModal)}
        </Row>
      </Row>
      <AddPayment
        hideDefaultCreateBtn
        form={addPaymentForm}
        initialValues={{ account_id: Number(financialAccountId) }}
        openedShift={openedShift}
      />
      <TransferAccounts
        callbackFunc={getResults}
        currentAccountId={currentAccountId}
      />
    </>
  );
};

export default FinancialAccountsDetailsPayments;
