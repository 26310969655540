import { Form, FormInstance, Select } from 'antd';
import VLXInputNumber from 'components/VLXInputNumber';
import useClientId from 'hooks/useClientId';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import { IAppState } from 'interfaces/app-state';
import { ILocale } from 'interfaces/locale';
import { IReplenishmentBalance } from 'interfaces/replenishment';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clientBalanceReplenishment } from 'redux/clients/actions';
import { getAccounts } from 'redux/finances/financialAccounts/actions';
import { ShowAccountResponse } from 'services/clinic/accounts/models/ShowAccountResponse';
import { requiredRule } from 'utils/form-rules';

const { Option } = Select;

type Props = React.PropsWithChildren<{
  form: FormInstance;
  locale: ILocale;
}>;

export const ReplenishmentPopupForm = ({
  form,
  children,
  locale
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const clinicId = useClinicId();
  const clientId = useClientId();
  const currencies = useLocale('private.currencies').labels;

  const accountsData = useSelector<IAppState, ShowAccountResponse[]>(
    (state) => state.financialAccounts.data
  );

  useEffect(() => {
    form.resetFields();
    dispatch(getAccounts(clinicId, 0));
  }, []);

  const onSubmit = (data: IReplenishmentBalance) => {
    dispatch(
      clientBalanceReplenishment(clinicId, clientId, data)
    );
  };

  const accountList = useMemo(
    () =>
      accountsData
        .map((item) => {
          return (
            <Option key={item.name} title={item.name} value={item.id}>
              {item?.name} ({item?.current_balance} {currencies.uah})
            </Option>
          );
        }),
    [accountsData]
  );

  return (
    <Form onFinish={onSubmit} autoComplete='off' layout='vertical' form={form}>
      <Form.Item
        rules={[
          requiredRule()
        ]}
        className='add-equipment-form__multi-select-field'
        label={locale.labels.account}
        name='account_id'
      >
        <Select
          style={{ overflow: 'hidden' }}
          filterOption={(input, option) => {
            return option?.title.toString().toLowerCase().includes(input?.toLowerCase());
          }}
          showSearch
        >
          {accountList}
        </Select>
      </Form.Item>
      <Form.Item
        label={locale.labels.sum}
        rules={[
          requiredRule()
        ]}
        name='amount'
      >
        <VLXInputNumber placeholder='0' type='number' precision={2} step={0.1} min={0} />
      </Form.Item>
      {children}
    </Form>
  );
};
