import '../scss/Provider.scss';

import { Button, Col, Form, FormInstance, Input, Row, Typography } from 'antd';
import { useLocale } from 'hooks/useLocale';
import React, { ReactNode, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { createProvider, updateProvider } from 'redux/inventory/providers/actions';
import { ISelectedProvidersItem } from 'services/interfaces/clinics/inventory/providers/ISelectedProvidersItemsData';
import { maxRule, patternRule, PATTERNS, requiredRule } from 'utils/form-rules';

import PhoneFieldFormItem from '../../../../components/PhoneFieldFormItem';
import useClinicId from '../../../../hooks/useClinicId';

const { TextArea } = Input;

type Props = {
  form: FormInstance;
  children: ReactNode;
  formEditMode?: boolean;
  onFieldsChange?: (changedFields: any, allFields: any) => void;
  initialValues?: ISelectedProvidersItem;
  callBackAfterCreate?: (data: ISelectedProvidersItem) => void;
  defaultProviderNameValue?: string;
};

export const AddProviderForm = ({
  form,
  initialValues,
  formEditMode,
  children,
  onFieldsChange,
  callBackAfterCreate,
  defaultProviderNameValue
}: Props) => {
  const dispatch = useDispatch();
  const locale = useLocale('private.inventory.providers');
  const clinicId = useClinicId();

  useEffect(() => {
    form.resetFields();
  }, [initialValues]);

  const onSubmit = (data: any) => {
    const apiData = { ...data };
    apiData.emails = apiData.emails ? JSON.stringify(apiData.emails) : [];
    apiData.phone_numbers = JSON.stringify(apiData.phone_numbers);
    if (formEditMode) {
      dispatch(updateProvider(clinicId, initialValues.id, apiData));
    } else {
      dispatch(createProvider(clinicId, apiData, callBackAfterCreate));
    }
  };

  const parseValues = () => {
    if (initialValues) {
      const data = { ...initialValues };
      data.emails = JSON.parse(initialValues.emails);
      data.phone_numbers = JSON.parse(initialValues.phone_numbers);
      return data;
    }
  };

  return (
    <Form
      onFinish={onSubmit}
      onFieldsChange={onFieldsChange}
      autoComplete='off'
      layout='vertical'
      form={form}
      initialValues={parseValues()}
    >
      <Form.Item name='clinic_id' hidden>
        <Input />
      </Form.Item>
      <Row gutter={[20, 10]}>
        <Col span={24} md={12}>
          <Form.Item
            label={locale.labels.name}
            name={'name'}
            rules={[requiredRule()]}
            initialValue={defaultProviderNameValue}
          >
            <Input />
          </Form.Item>
          <Form.Item name='emails' initialValue={!initialValues && ['']} hidden>
            <Input />
          </Form.Item>
          <Form.Item
            name='phone_numbers'
            initialValue={!initialValues && [{ country_code: '380', phone_number: '' }]}
            hidden
          >
            <Input />
          </Form.Item>
          <Typography className='email-label'>{locale.labels.emails}</Typography>
          <Form.List name='emails'>
            {(fields, { add, remove }) => (
              <>
                {fields?.map(({ key, name, ...restField }) => (
                  <Row
                    key={key}
                    style={{ justifyContent: 'space-between', paddingRight: key === 0 ? 0 : 20 }}
                  >
                    <Col span={key === 0 ? 24 : 22}>
                      <Form.Item
                        name={[name]}
                        rules={[patternRule(PATTERNS.EMAIL, locale.messages.emailNotValid)]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    {key > 0 && (
                      <Col span={1}>
                        <Button
                          onClick={(e) => {
                            remove(name);
                            formEditMode && onFieldsChange([{ e }], [{ e }]);
                          }}
                          danger
                          type='primary'
                          shape={'circle'}
                          className={'inventory-update-buttons'}
                        >
                          <span className='icofont icofont-trash' />
                        </Button>
                      </Col>
                    )}
                  </Row>
                ))}
                <Row>
                  <Form.Item>
                    <Button
                      className='icofont icofont-plus'
                      type={'primary'}
                      style={{
                        borderRadius: 30,
                        fontWeight: 'bold'
                      }}
                      onClick={() => add()}
                    >
                      {locale.buttons.addMore}
                    </Button>
                  </Form.Item>
                </Row>
              </>
            )}
          </Form.List>
          <Typography className='email-label'>
            <span style={{ color: '#ff4d4f', paddingRight: 4 }}>*</span>
            {locale.labels.contactPhone}
          </Typography>
          <Form.List name='phone_numbers'>
            {(fields, { add, remove }) => (
              <>
                {fields?.map(({ key, name, ...restField }) => (
                  <Row
                    key={key}
                    style={{ justifyContent: 'space-between', paddingRight: key === 0 ? 0 : 20 }}
                  >
                    <Col span={key === 0 ? 24 : 22}>
                      <PhoneFieldFormItem
                        formItemPhonePrefix={[name, 'country_code']}
                        formItemPhoneNumber={[name, 'phone_number']}
                        hideLabel
                      />
                    </Col>
                    {key > 0 && (
                      <Col span={1} md={1}>
                        <Button
                          onClick={(e) => {
                            remove(name);
                            formEditMode && onFieldsChange([{ e }], [{ e }]);
                          }}
                          danger
                          type='primary'
                          shape={'circle'}
                          className={'inventory-update-buttons'}
                        >
                          <span className='icofont icofont-trash' />
                        </Button>
                      </Col>
                    )}
                  </Row>
                ))}
                <Row>
                  <Form.Item>
                    <Button
                      className='icofont icofont-plus'
                      type={'primary'}
                      style={{
                        borderRadius: 30,
                        fontWeight: 'bold'
                      }}
                      onClick={() => add()}
                    >
                      {locale.buttons.addMore}
                    </Button>
                  </Form.Item>
                </Row>
              </>
            )}
          </Form.List>
        </Col>
        <Col span={24} md={12}>
          <Form.Item
            label={locale.labels.address}
            name='address'
            rules={[maxRule(255, locale.errors.length_rule_255)]}
          >
            <TextArea rows={2} />
          </Form.Item>
          <Form.Item
            label={locale.labels.comments}
            name='comments'
            rules={[maxRule(255, locale.errors.length_rule_255)]}
          >
            <TextArea rows={2} />
          </Form.Item>
        </Col>
      </Row>
      {children}
    </Form>
  );
};
