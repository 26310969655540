import { Col, DatePicker, Form, FormInstance, Input, Row, Select } from 'antd';
import dayjs from 'dayjs';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import { IAppState } from 'interfaces/app-state';
import { AddProvider } from 'pages/inventory/providers/modal/AddProvider';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProviders, setShowCreateProviderModal } from 'redux/inventory/providers/actions';
import { IInvoiceDataItem } from 'services/interfaces/clinics/inventory/invoice/IInvoiceData';
import { requiredRule } from 'utils/form-rules';

const { TextArea } = Input;
const { Option } = Select;

type Props = React.PropsWithChildren<{
  onFieldsChange?: (e) => void;
  onFinish: (payload) => void;
  form: FormInstance;
  initialValues?: IInvoiceDataItem;
}>;

const InvoiceForm = ({
  onFieldsChange,
  onFinish,
  form,
  initialValues,
  children
}: Props): JSX.Element => {
  const locale = useLocale('private.inventory.invoices');
  const dispatch = useDispatch();
  const clinicId = useClinicId();

  const { data } = useSelector<IAppState, any>((state) => state.providers);

  const [providerSearchValue, setProviderSearchValue] = useState('');

  useEffect(() => dispatch(getProviders(clinicId, 0)), []);

  const providersList = data?.map((item) => {
    return (
      <Option key={item.id} title={item.name} value={item.id}>
        {item.name}
      </Option>
    );
  });

  const filterOptions = (input: string, option) =>
    option?.title.toLowerCase().includes(input.toLocaleLowerCase());

  return (
    <>
      <Form
        key={'InvoiceForm'}
        onFieldsChange={onFieldsChange}
        onFinish={onFinish}
        autoComplete='off'
        layout='vertical'
        form={form}
        initialValues={initialValues}
      >
        <Row gutter={[40, 0]} className={'form-row'}>
          <Col span={24} sm={20} md={12} xl={12} xxl={12} className={'form-col'}>
            <Form.Item
              name='invoice_number'
              label={locale.labels.invoiceNumber}
              rules={[requiredRule(locale.messages.invoiceNumberEmpty)]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name='provider_id'
              label={locale.labels.provider}
              rules={[requiredRule(locale.messages.providerEmpty)]}
            >
              <Select
                showSearch
                filterOption={filterOptions}
                onSearch={(val) => setProviderSearchValue(val)}
                notFoundContent={
                  <div
                    onClick={() => dispatch(setShowCreateProviderModal(true))}
                    id='addNewServiceGroupBtn'
                  >
                    + {locale.labels.addProvider}
                  </div>
                }
              >
                {providersList}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24} sm={20} md={12} xl={12} xxl={12} className={'form-col'}>
            <Form.Item
              name='purchase_date'
              label={`${locale.labels.date}`}
              initialValue={initialValues?.purchase_date || dayjs()}
              rules={[requiredRule(locale.messages.dateEmpty)]}
            >
              <DatePicker disabledDate={(d) => !d || d.isAfter(dayjs())} format='YYYY-MM-DD' />
            </Form.Item>
            <Form.Item name='notes' label={`${locale.labels.notes}`}>
              <TextArea />
            </Form.Item>
          </Col>
        </Row>
        {children}
      </Form>
      <AddProvider
        callBackAfterCreate={(data) => {
          dispatch(
            getProviders(clinicId, 0, [], { sort_key: 'name', sort_order: 'asc' }, () => {
              form.setFieldValue('provider_id', data?.id);
            })
          );
        }}
        defaultProviderNameValue={providerSearchValue}
      />
    </>
  );
};

export default InvoiceForm;
