import { Form } from 'antd';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import VLXModal from 'components/VLXModal';
import { ILocale } from 'interfaces/locale';
import React, { useState } from 'react';
import { ISelectedAuditItem } from 'services/interfaces/clinics/inventory/audit/ISelectedAuditItemsData';

import { PerformAuditItemForm } from '../AuditForms/PerformAuditItemForm';

type Props = {
  selectedStock: ISelectedAuditItem;
  locale: ILocale;
  setShowPerformAuditItemModal: (show) => void;
};

export const PerformAuditItemModal = ({
  selectedStock,
  locale,
  setShowPerformAuditItemModal
}: Props): JSX.Element => {
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const [form] = Form.useForm();

  const onConfirmCancelClick = (): void => {
    if (form.isFieldsTouched()) {
      setShowCancelModal(true);
    } else {
      setShowPerformAuditItemModal(false);
    }
  };

  return (
    <>
      <VLXModal
        title={`${locale.labels.stockAudit} ${selectedStock.name} (${selectedStock.manufacturer})`}
        centered
        open
        form={form}
        onModalClose={() => {
          setShowPerformAuditItemModal(false);
        }}
        width={selectedStock.packaging_format === 'none' ? 800 : 1100}
      >
        <PerformAuditItemForm
          locale={locale}
          form={form}
          onSubmit={() => setShowPerformAuditItemModal(false)}
          selectedStock={selectedStock}
        />
      </VLXModal>
      <ConfirmCancelModal
        cancelText={locale.messages.unsavedChangesWarning}
        open={showCancelModal}
        onOk={onConfirmCancelClick}
        onCancel={() => setShowCancelModal(false)}
      />
    </>
  );
};
