import { Button, Checkbox, Form, Input, Spin } from 'antd';
import * as localStorage from 'local-storage';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { requiredRule } from 'utils/form-rules';

import { redirectURL } from '../../../constants/comon';
import { useLocale } from '../../../hooks/useLocale';
import { useQuery } from '../../../hooks/useQuery';
import { getUser } from '../../../redux/user/actions';
import { login } from '../../../services/authService';
import LinkButton from '../linkButton/LinkButton';

const Login = ({ onNavigate, changeVisible }: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = useQuery().get(redirectURL) || '/';
  const [loading, setLoading] = useState(false);
  const locale = useLocale('public.modals.login');
  const [form] = Form.useForm();

  const onSubmit = (values: any) => {
    setLoading(true);
    login(values)
      .then((data) => {
        if (data.email_confirmed === false) {
          changeVisible(false);
          navigate(`/confirm-email/${data.mail_token}?locale=${localStorage.get('locale')}`);
          navigate(0);
        } else {
          dispatch(
            getUser(() => {
              navigate(query);
            })
          );
        }
      })
      .catch(() => {
        form.setFields([
          {
            name: 'password',
            errors: [locale.messages.authError]
          }
        ]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Spin spinning={loading}>
      <Form
        initialValues={{ remember: true }}
        onFinish={onSubmit}
        form={form}
        autoComplete='off'
        layout='vertical'
      >
        <Form.Item
          label={locale.labels.emailPhone}
          name='email'
          rules={[requiredRule(locale.messages.loginEmpty)]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={locale.labels.password}
          name='password'
          rules={[requiredRule(locale.messages.passwordEmpty)]}
        >
          <Input.Password autoComplete='on' />
        </Form.Item>
        <Form.Item name='remember' valuePropName='checked'>
          <Checkbox>{locale.labels.rememberMe}</Checkbox>
        </Form.Item>
        <Form.Item>
          <Button block type='primary' htmlType='submit'>
            {locale.buttons.login}
          </Button>
        </Form.Item>
      </Form>
      <LinkButton onClick={() => onNavigate('forgotPassword')}>
        {locale.links.forgotPassword}
      </LinkButton>
      <br />
      {locale.labels.dontHaveAccount}{' '}
      <LinkButton onClick={() => onNavigate('userRegistration')}>{locale.links.signUp}</LinkButton>
    </Spin>
  );
};

export default Login;
