import './AddPayment.scss';

import { Col, DatePicker, Form, Input, Row, Select } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { maxRule, requiredRule } from 'utils/form-rules';

import VLXInputNumber from '../../../../components/VLXInputNumber';
import { billType } from '../../../../constants/dictionary/default/selectOptions';
import useClinicId from '../../../../hooks/useClinicId';
import useLocalizedList from '../../../../hooks/useLocalizedList';
import { IAppState } from '../../../../interfaces/app-state';
import { indexBillPaymentTypes } from '../../../../redux/dictionaries/bill-payment-types/actions';
import { showModal } from '../../../../redux/dictionaries/dictionary/actions';
import { getAccounts } from '../../../../redux/finances/financialAccounts/actions';
import { createPayment, updatePayment } from '../../../../redux/finances/payments/actions';
import AddDictionary from '../../addDictionary/AddDictionary';

const { TextArea } = Input;
const { Option } = Select;

const AddPaymentForm = ({
  form,
  initialValues,
  formEditMode,
  children,
  locale,
  setFormIsTouched,
  callbackFunc
}: any) => {
  const dispatch = useDispatch();
  const clinicId = useClinicId();

  const accountsData = useSelector<IAppState, any>((state) => state.financialAccounts.data);
  const billPaymentTypes = useSelector<IAppState, any>((state) => state.billPaymentTypes.data);

  const [billCategorySearchValue, setBillCategoryearchValue] = useState('');

  const editingOfNameDisabled =
    ['sale', 'invoice', 'appointment'].includes(initialValues?.bill?.sub_type) ||
    initialValues?.name === 'internal_transfer';

  useEffect(() => {
    form.resetFields();
    dispatch(indexBillPaymentTypes(clinicId, 0));
  }, []);

  useEffect(() => {
    if (clinicId) {
      dispatch(getAccounts(clinicId, 0));
    }
  }, [clinicId]);

  useEffect(() => {
    form.resetFields();
  }, [initialValues]);

  const onSubmit = (data: any) => {
    const apiData = { ...data };
    if (data?.amount) {
      apiData.amount = Number(data.amount);
    }
    if (data.date) {
      apiData.date = dayjs(data.date).utc().format('DD.MM.YYYYTHH:mm:ss');
    }

    if (formEditMode) {
      dispatch(updatePayment(clinicId, initialValues.id, apiData));
    } else {
      dispatch(createPayment(clinicId, apiData, callbackFunc));
    }
  };

  const accountsList = accountsData.map((item) => {
    return (
      <Option key={item.name} title={item.name} value={item.id}>
        {item?.name} {item?.account_number ? `, ${item?.account_number}` : ''}
      </Option>
    );
  });

  const getInitValues = () => {
    const data = { ...initialValues };
    if (data?.financial_account?.id) {
      data.account_id = data?.financial_account?.id;
    }
    if (initialValues?.date) {
      data.date = dayjs(data.date);
    }
    if (data.name === 'internal_transfer') {
      data.name = locale.labels.transferBetweenAccounts;
    }
    return data;
  };

  const billCategories = billPaymentTypes.map((item) => {
    return (
      <Option key={item.name} title={item.name} value={item.id}>
        {item.name}
      </Option>
    );
  });

  const filterBillCategoryOption = (input: string, option) => {
    if (option?.title.toLowerCase().includes(input.toLocaleLowerCase())) {
      return true;
    }
    return false;
  };

  return (
    <Form
      onFinish={onSubmit}
      autoComplete='off'
      layout='vertical'
      form={form}
      initialValues={getInitValues()}
    >
      <Form.Item name='clinic_id' hidden>
        <Input />
      </Form.Item>
      <Row gutter={[20, 10]}>
        <Col span={24} md={12}>
          <Form.Item label={locale.labels.name} name={'name'} rules={[requiredRule()]}>
            <Input disabled={editingOfNameDisabled} />
          </Form.Item>
          <Form.Item label={locale.labels.amount} rules={[requiredRule()]} name='amount'>
            <VLXInputNumber
              placeholder='0'
              type='number'
              disabled={formEditMode}
              precision={2}
              step={0.1}
              min={0}
              onChange={(value) => {
                form.setFieldsValue({ amount: value });
              }}
            />
          </Form.Item>
          <Form.Item name='payment_type' rules={[requiredRule()]} label={locale.labels.paymentType}>
            <Select
              disabled={formEditMode}
              filterOption={false}
              options={useLocalizedList(billType)}
            />
          </Form.Item>
          <Form.Item name='bill_payment_type_id' label={locale.labels.category}>
            <Select
              showSearch
              onSearch={(val) => setBillCategoryearchValue(val)}
              filterOption={filterBillCategoryOption}
              notFoundContent={
                <div onClick={() => dispatch(showModal(true))} id='addNewServiceGroupBtn'>
                  + {locale.labels.addBillCategory}
                </div>
              }
            >
              {billCategories}
            </Select>
          </Form.Item>
          <Form.Item
            name={'date'}
            initialValue={initialValues?.date ? initialValues?.date : dayjs()}
            label={locale.labels.date}
          >
            <DatePicker format='YYYY-MM-DD' />
          </Form.Item>
        </Col>
        <Col span={24} md={12}>
          <Form.Item
            rules={[requiredRule()]}
            className='add-equipment-form__multi-select-field'
            label={locale.labels.account}
            name='account_id'
          >
            <Select
              style={{ overflow: 'hidden' }}
              filterOption={(input, option) => {
                return option?.title.toString().toLowerCase().includes(input?.toLowerCase());
              }}
              showSearch
            >
              {accountsList}
            </Select>
          </Form.Item>
          <Form.Item
            label={locale.labels.notes}
            name='notes'
            rules={[maxRule(300, locale.errors.length_rule_300)]}
          >
            <TextArea rows={2} />
          </Form.Item>
          <Form.Item
            label={locale.labels.description}
            name='description'
            rules={[maxRule(300, locale.errors.length_rule_300)]}
          >
            <TextArea rows={2} />
          </Form.Item>
        </Col>
      </Row>
      {children}
      <AddDictionary
        defaultDictionaryNameValue={billCategorySearchValue}
        popupTitle={locale.labels.addBillCategory}
        actionType={'bill_category'}
        hideCreateBtn
        callBackAfterCreate={(data) => {
          dispatch(
            indexBillPaymentTypes(clinicId, 0, [], () => {
              form.setFieldValue('bill_payment_type_id', data?.id);
              if (formEditMode) {
                setFormIsTouched(true);
              }
            })
          );
        }}
      />
    </Form>
  );
};

export default AddPaymentForm;
