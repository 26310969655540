import { Button, Form } from 'antd';
import VLXModal from 'components/VLXModal';
import * as config from 'config/config-manager.ts';
import { useLocale } from 'hooks/useLocale';
import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { toggleSidebar } from 'redux/settings/actions';

import ForgotPassword from './ForgotPassword';
import Localization from './Localization';
import Login from './Login';
import RegistrationRequestForm from './RegistrationRequestForm';
import UserRegistration from './UserRegistration';

export const HeaderButtons = ({ containerClass }: { containerClass: string }): JSX.Element => {
  const locale = useLocale('public.landing-page').labels;
  const dispatch = useDispatch();
  const showModal = (modalName) => {
    changeVisible(true);
    changeModal(modals[modalName]);
  };

  const [registrationForm] = Form.useForm();
  const [registrationRequest] = Form.useForm();
  const [visible, changeVisible] = useState(false);
  const modals = useMemo(
    () => ({
      login: {
        title: locale.loginModalTitle,
        form: null,
        content: showModal && <Login onNavigate={showModal} changeVisible={changeVisible} />,
        width: 320
      },
      forgotPassword: {
        title: locale.forgotPasswordModal,
        form: null,
        content: <ForgotPassword closeModal={() => changeVisible(false)} />,
        width: 300
      },
      userRegistration: {
        title: locale.singUpButton,
        form: registrationForm,
        content: (
          <UserRegistration form={registrationForm} closeModal={() => changeVisible(false)} />
        ),
        width: 800
      },
      registrationRequest: {
        title: locale.registrationRequest,
        form: registrationRequest,
        content: (
          <RegistrationRequestForm
            form={registrationForm}
            closeModal={() => changeVisible(false)}
          />
        ),
        width: 800
      }
    }),
    [locale]
  );
  const [modal, changeModal] = useState(modals.login);

  return (
    <>
      <div className={containerClass} style={{ alignItems: 'center' }}>
        <div className='header-actions-col__layout-anchors'>
          <Link
            onClick={() => dispatch(toggleSidebar())}
            className='header-link-button'
            to='#features'
          >
            {locale.features}
          </Link>
          <Link
            onClick={() => dispatch(toggleSidebar())}
            className='header-link-button'
            to='#getting_started'
          >
            {locale.gettingStarted}{' '}
          </Link>
          <Link onClick={() => dispatch(toggleSidebar())} className='header-link-button' to='#faq'>
            {locale.faq}{' '}
          </Link>
        </div>
        <Localization />
        <Button size='small' type='primary' onClick={() => showModal('login')}>
          {locale.loginButton}
        </Button>
        {!config.disabledForProd && (
          <Button size='small' onClick={() => showModal('userRegistration')}>
            {locale.singUpButton}
          </Button>
        )}
        {/* <Button size='small' onClick={() => showModal('registrationRequest')}>{locale.registrationRequest}</Button> */}
      </div>
      {visible && (
        <VLXModal
          title={modal.title}
          form={modal.form}
          open={visible}
          onModalClose={() => changeVisible(false)}
          width={modal.width}
        >
          {modal.content}
        </VLXModal>
      )}
    </>
  );
};
