import './ClientDetails.scss';

import { Avatar as AvatarView, Button, Col, List, Row, Select, Tooltip, Typography } from 'antd';
import checked from 'assets/img/checked.svg';
import close from 'assets/img/close.svg';
import tomb from 'assets/img/tomb.svg';
import ConfirmDeleteModal from 'components/ConfirmDeleteModal';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import { IAppState } from 'interfaces/app-state';
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteAppointmentDetailsPatient } from 'redux/appointments/actions';
import { localizedPetData } from 'utils/localized-pet-data';

import { getAnimalAge, getDefaultAvatar } from '../patients/helpers/PatientHelper';

const { Option } = Select;

const ClientDetailsPetsList = ({
  appointment_id,
  onPetsSearch,
  disabled,
  clientPatients,
  addMarkIcon,
  setSelectedPatient,
  selectedPatient,
  showDeletePatientIcon,
  lgRowWidth,
  addNewPetAction
}: any): JSX.Element => {
  const [showDeletePatientModal, setShowDeletePatientModal] = useState<boolean>(false);
  const [patientToDelete, setPatientToDelete] = useState(null);
  const clinicId = useClinicId();
  const dispatch = useDispatch();
  const locale = useLocale('private.clients.client');
  const patientLocale = useLocale('private.patients.patient-page');

  const localeCode = useSelector<IAppState, string>(({ localeCode }) => localeCode);

  const getPatientCheckedIcon = (pet: any) => {
    const appDetFilled = pet?.health_record?.completed;
    let stockDetFilled = false;
    if (pet?.health_record?.services?.length) {
      if (pet?.health_record?.services.some((service) => service.stock_saved === false)) {
        stockDetFilled = false;
      } else {
        stockDetFilled = true;
      }
    }
    return appDetFilled && stockDetFilled;
  };

  const patientsList = useMemo(
    () =>
      clientPatients.map((item) => {
        return (
          <Option key={`patient_${item.id}`} value={item.id}>
            <div className='patient-select-mobile-list-option'>
              <div>{item.name}</div>
              <div className='patient-select-mobile-list-option__icons'>
                {addMarkIcon && getPatientCheckedIcon(item) && (
                  <img src={checked} className='patient-select-mobile-list-option__mark-icon' />
                )}
                {showDeletePatientIcon && !disabled && (
                  <img
                    className='patient-select-mobile-list-option__delete-icon delete-patient-icon'
                    src={close}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      e.nativeEvent.stopImmediatePropagation();
                      deletePatient(item.id);
                    }}
                  />
                )}
              </div>
            </div>
          </Option>
        );
      }),
    [clientPatients]
  );

  const setSelectedPatientFromSelect = (selPetId: any) => {
    setSelectedPatient(clientPatients.find((el) => el.id === selPetId));
  };

  const deletePatient = (id: any) => {
    setPatientToDelete(id);
    setShowDeletePatientModal(true);
  };

  const onConfirmDeletePatientClick = () => {
    dispatch(deleteAppointmentDetailsPatient(clinicId, appointment_id, patientToDelete));
    setPatientToDelete(null);
    setShowDeletePatientModal(false);
  };

  return (
    <>
      <Col className='client-details__mobile-pets-list'>
        <Select
          className='patient-select-mobile-list'
          showSearch
          value={selectedPatient?.id}
          filterOption={false}
          onChange={setSelectedPatientFromSelect}
          onSearch={onPetsSearch}
          disabled={clientPatients.length <= 1}
        >
          {patientsList}
        </Select>
        <Button
          type='primary'
          onClick={addNewPetAction}
          className='icofont icofont-plus'
          style={{ borderRadius: 30, width: 40, marginLeft: 10, left: 5, right: 10 }}
        ></Button>
      </Col>
      <Col
        span={24}
        lg={lgRowWidth || 10}
        className='client-details__list-container'
        id='client-pets-list'
      >
        <List className='client-details__list'>
          {clientPatients.map((el: any) => (
            <List.Item
              style={{ backgroundColor: el.id === selectedPatient?.id ? '#ececec' : 'transparent' }}
              onClick={() => setSelectedPatient(el)}
              key={el.id}
              className='client-details__list-item'
            >
              <Row>
                <AvatarView
                  size={44}
                  style={{ marginRight: 15 }}
                  src={el?.avatar ? el?.avatar : getDefaultAvatar(el?.species)}
                />
                <Col>
                  <Typography id='list-pet-name' style={{ fontSize: 16, fontWeight: 'bold' }}>
                    {el?.name}
                  </Typography>
                  <Row>
                    {el?.species && (
                      <Typography id='list-pet-species'>
                        {localizedPetData(el, localeCode, true).species}
                      </Typography>
                    )}
                    <Typography>{el?.species && el?.birth_date && ',\u00A0'}</Typography>
                    {el?.date_of_death ? (
                      <Typography id='patient-death-icon'>
                        <Tooltip
                          trigger={'hover'}
                          placement='topLeft'
                          title={getAnimalAge(patientLocale, el?.birth_date, el?.date_of_death)}
                        >
                          {'\u00A0'}
                          <img src={tomb} style={{ width: 22, height: 22 }} />
                        </Tooltip>
                      </Typography>
                    ) : (
                      <Typography id='list-pet-age'>
                        {getAnimalAge(locale, el?.birth_date)}
                      </Typography>
                    )}
                  </Row>
                </Col>
              </Row>
              {addMarkIcon && getPatientCheckedIcon(el) && (
                <img src={checked} style={{ width: 32, height: 32, paddingRight: 10 }} />
              )}
              {showDeletePatientIcon && !disabled && (
                <img
                  className='delete-patient-icon'
                  src={close}
                  onClick={() => deletePatient(el.id)}
                />
              )}
            </List.Item>
          ))}
        </List>
        <Row className='client-details__list__add-pet-btn'>
          {!disabled && (
            <Button
              id='add-pet'
              type='primary'
              onClick={addNewPetAction}
              className='icofont icofont-plus'
              style={{ borderRadius: 30, fontWeight: 'bold' }}
            >
              {locale.buttons.addNewPet}
            </Button>
          )}
        </Row>
        <ConfirmDeleteModal
          type={'device'}
          open={showDeletePatientModal}
          onOk={onConfirmDeletePatientClick}
          customTitle={locale.labels.confirmUnassignPatientFromAppointment}
          onCancel={() => {
            setShowDeletePatientModal(false);
            setPatientToDelete(null);
          }}
        />
      </Col>
    </>
  );
};

export default ClientDetailsPetsList;
