import './AddDictionary.scss';

import { Form, Input, Row } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { requiredRule } from 'utils/form-rules';

import useClinicId from '../../../hooks/useClinicId';
import { IAppState } from '../../../interfaces/app-state';
import { createBillPaymentType } from '../../../redux/dictionaries/bill-payment-types/actions';
import { createServiceGroup } from '../../../redux/dictionaries/service-groups/actions';
import { createStockCategory } from '../../../redux/dictionaries/stock-categories/actions';
import { createStockSubCategory } from '../../../redux/dictionaries/stock-sub-categories/actions';
import { createStockType } from '../../../redux/dictionaries/stock-types/actions';

const { TextArea } = Input;

const AddDictionaryForm = ({
  defaultDictionaryNameValue,
  form,
  initialValues,
  children,
  onFieldsChange,
  locale,
  callBackAfterCreate,
  actionType
}: any) => {
  const dispatch = useDispatch();
  const clinicId = useClinicId();
  const commonState = useSelector<IAppState, any>((state) => state.common);

  useEffect(() => {
    if (!commonState.backendError) {
      form.resetFields();
    }
  }, []);

  const onSubmit = (data: any) => {
    console.log(actionType, 'actionType');
    if (actionType === 'service_group') {
      dispatch(createServiceGroup(clinicId, data, null, null, callBackAfterCreate));
    }
    if (actionType === 'bill_category') {
      dispatch(createBillPaymentType(clinicId, data, null, null, callBackAfterCreate));
    }
    if (actionType === 'stock_type_id') {
      dispatch(createStockType(clinicId, data, null, null, callBackAfterCreate));
    }
    if (actionType === 'stock_category_id') {
      dispatch(createStockCategory(clinicId, data, null, null, callBackAfterCreate));
    }
    if (actionType === 'stock_sub_category_id') {
      dispatch(createStockSubCategory(clinicId, data, null, null, callBackAfterCreate));
    }
  };

  const getInitValues = () => {
    const data = { ...initialValues };
    if (initialValues?.tools?.length) {
      data.tools = initialValues.tools.map((el) => el.id);
    }
    if (initialValues?.equipment?.length) {
      data.equipment = initialValues.equipment.map((el) => el.id);
    }
    delete data.stock;
    return data;
  };

  return (
    <Form
      onFinish={onSubmit}
      onFieldsChange={onFieldsChange}
      autoComplete='off'
      layout='vertical'
      form={form}
      initialValues={getInitValues()}
    >
      <Row
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Form.Item
          label={locale.labels.name}
          name='name'
          initialValue={defaultDictionaryNameValue}
          rules={[requiredRule(locale.messages.specifyName)]}
        >
          <Input />
        </Form.Item>
        <Form.Item label={locale.labels.description} name='description'>
          <TextArea rows={3} />
        </Form.Item>
      </Row>
      {children}
    </Form>
  );
};

export default AddDictionaryForm;
