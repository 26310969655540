import './AddClientForm.scss';

import { Button, Col, DatePicker, Divider, Form, Input, Row, Select, Switch } from 'antd';
import PhoneFieldFormItem from 'components/PhoneFieldFormItem';
import { getIcon, socialMediaLinks } from 'constants/dictionary/default/socialMediaOptions';
import dayjs from 'dayjs';
import useClinicId from 'hooks/useClinicId';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createClient, updateClient } from 'redux/clients/actions';
import { patternRule, PATTERNS, requiredRule } from 'utils/form-rules';
import { trimSocialLinks } from 'utils/trimSocialLinks';

const { TextArea } = Input;
const { Option } = Select;

const AddClientForm = ({
  form,
  initialValues,
  editMode,
  locale,
  children,
  callbackFunction,
  simplifiedValidation
}: any): JSX.Element => {
  const dispatch = useDispatch();
  const [showBlacklistedFields, setShowBlacklistedFields] = useState(false);
  const clinicId = useClinicId();
  const { messages } = locale;

  useEffect(() => {
    form.resetFields();
  }, []);

  const onSubmit = (data: any) => {
    const apiData = {
      ...data,
      social_media_links: trimSocialLinks(data.social_media_links)
    };
    if (data.date_of_birth) {
      apiData.date_of_birth = dayjs(data.date_of_birth).format('DD.MM.YYYY');
    } else apiData.date_of_birth = null;
    if (editMode) {
      dispatch(updateClient(clinicId, apiData, initialValues.id));
    } else {
      dispatch(createClient(clinicId, apiData, callbackFunction));
    }
  };

  function remapInitialValues() {
    if (initialValues) {
      return {
        ...initialValues,
        social_media_links: JSON.parse(initialValues?.social_media_links),
        date_of_birth: initialValues?.date_of_birth !== null && dayjs(initialValues?.date_of_birth)
      };
    }
  }

  function socialMediaList() {
    return socialMediaLinks.map((link, index) => {
      return (
        <Option key={index} value={link.value}>
          {link.value}
          {getIcon(link.key === 'other' ? 'page' : link.key, 16)}
        </Option>
      );
    });
  }

  useEffect(() => form.resetFields(), []);

  useEffect(() => {
    form.resetFields();
    if (showBlacklistedFields !== initialValues?.blacklisted?.status)
      setShowBlacklistedFields(initialValues?.blacklisted?.status);
  }, [initialValues]);

  return (
    <Form
      onFinish={onSubmit}
      autoComplete='off'
      layout='vertical'
      form={form}
      initialValues={remapInitialValues()}
    >
      <Row gutter={[20, 10]}>
        <Col span={24} md={12}>
          <Form.Item
            label={locale.labels.firstName}
            name={['first_name']}
            rules={[
              requiredRule(messages.nameEmpty),
              patternRule(PATTERNS.NAME, messages.nameNotValid)
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={locale.labels.middleName}
            name={['middle_name']}
            rules={[patternRule(PATTERNS.NAME, messages.nameNotValid)]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={locale.labels.lastName}
            name={['last_name']}
            rules={[
              patternRule(PATTERNS.NAME, messages.lastNameNotValid),
              ...(!simplifiedValidation ? [requiredRule(messages.lastNameEmpty)] : [])
            ]}
          >
            <Input />
          </Form.Item>
          <PhoneFieldFormItem />
          <Form.Item
            label={locale.labels.email}
            name={['email']}
            rules={[patternRule(PATTERNS.EMAIL, messages.emailNotValid)]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            help={messages.specialNotesVisibility}
            label={locale.labels.specialNotes}
            name={['private_notes']}
          >
            <TextArea rows={4} />
          </Form.Item>
        </Col>
        <Col span={24} md={12}>
          <Form.Item label={locale.labels.dateOfBirth} name='date_of_birth'>
            <DatePicker disabledDate={(d) => !d || d.isAfter(dayjs())} format='YYYY-MM-DD' />
          </Form.Item>
          <Form.Item label={locale.labels.homeAddress} style={{ marginBottom: 0 }}>
            <Form.Item
              name={['country']}
              style={{
                display: 'inline-block',
                width: 'calc(50% - 8px)',
                marginRight: '16px'
              }}
            >
              <Input placeholder={locale.placeholders.country} />
            </Form.Item>
            <Form.Item
              name={['region']}
              style={{
                display: 'inline-block',
                width: 'calc(50% - 8px)'
              }}
            >
              <Input placeholder={locale.placeholders.region} />
            </Form.Item>
          </Form.Item>
          <Form.Item name={['city']}>
            <Input placeholder={locale.placeholders.city} />
          </Form.Item>
          <Form.Item name={['address']}>
            <Input placeholder={locale.placeholders.address} />
          </Form.Item>
          <Form.Item
            className={'horizontal-label-form-item blacklisted-container'}
            name={['blacklisted', 'status']}
            valuePropName={'checked'}
            label={locale.labels.blacklisted}
          >
            <Switch onChange={(value) => setShowBlacklistedFields(value)} />
          </Form.Item>
          {showBlacklistedFields && (
            <>
              <Form.Item
                label={locale.labels.blacklistReason}
                name={['blacklisted', 'reason']}
                rules={[requiredRule(messages.blacklistReason)]}
              >
                <TextArea rows={2} />
              </Form.Item>
            </>
          )}
        </Col>
      </Row>
      <Divider />
      <Form.List name='social_media_links'>
        {(fields, { add, remove }) => (
          <>
            {fields?.map(({ key, name, ...restField }) => (
              <Row key={name} gutter={[20, 10]}>
                <Col span={22} md={11}>
                  <Form.Item
                    {...restField}
                    name={[name, 'type']}
                    rules={[requiredRule(messages.selectSocialNetwork)]}
                  >
                    <Select
                      placeholder={locale.placeholders.selectSocialMedia}
                      filterOption={(input, option) =>
                        option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      showSearch
                    >
                      {socialMediaList()}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={22} md={11}>
                  <Form.Item
                    {...restField}
                    name={[name, 'description']}
                    rules={[
                      requiredRule(messages.specifyLink),
                      patternRule(PATTERNS.SOCIAL_LINK, messages.invalidSocialLink)
                    ]}
                  >
                    <Input placeholder={locale.placeholders.linkToProfile} />
                  </Form.Item>
                </Col>
                <Button
                  onClick={() => {
                    remove(name);
                  }}
                  danger
                  type='primary'
                  shape={'circle'}
                  className={'inventory-update-buttons'}
                >
                  <span className='icofont icofont-trash' />
                </Button>
              </Row>
            ))}
            <Row>
              <Form.Item>
                <Button
                  className='icofont icofont-plus'
                  type={'primary'}
                  style={{
                    borderRadius: 30,
                    fontWeight: 'bold'
                  }}
                  onClick={() => add()}
                >
                  {fields.length ? locale.buttons.addMore : locale.buttons.addSocialLinks}
                </Button>
              </Form.Item>
            </Row>
          </>
        )}
      </Form.List>
      {children}
    </Form>
  );
};

export default AddClientForm;
