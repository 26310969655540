import axios from 'axios';

import { IPatient } from '../../interfaces/patient';
import {
  createClinicPatient,
  createClinicPatientDocument,
  getClinicPatientDetails,
  getClinicPatientDocumentsList,
  getClinicPatients,
  removeClinicPatient,
  updateClinicPatient,
  uploadAvatar,
} from '../../services/clinic/patients/patientsService';
import {
  addClinicPatientVaccination,
  deleteClinicPatientVaccination,
  getClinicPatientVaccination,
  getClinicPatientVaccinations,
  updateClinicPatientVaccination,
} from '../../services/clinic/patients/vaccinations/vaccinationsService';
import { getAppointmentDetailsPatients } from '../appointments/actions';
import { getClientPatients } from '../clients/actions';
import {
  ADD_PATIENT,
  AddPatientAction,
  DELETE_PATIENT,
  DeletePatientAction,
  EDIT_PATIENT,
  EditPatientAction,
  MERGE_PATIENT_DOCUMENTS_LIST,
  MergePatientDocumentsListAction,
  PatientsResetAction,
  RESET,
  SET_PATIENT,
  SET_PATIENT_ALL_VACCINATIONS_DATA,
  SET_PATIENT_DOCUMENTS_LIST,
  SET_PATIENT_SELECTED_VACCINATIONS,
  SET_PATIENT_VACCINATIONS,
  SET_PATIENTS,
  SET_PATIENTS_LOADING,
  SetLoadingAction,
  SetPatientAction,
  SetSelectedPatientAction,
  SetSelectedPatientAllVaccinationDataAction,
  SetSelectedPatientDocumentsListAction,
  SetSelectedPatientSelectedVaccinationAction,
  SetSelectedPatientVaccinationAction,
  SHOW_CREATE_MODAL,
  SHOW_UPDATE_MODAL,
  ShowCreateModalAction,
  ShowUpdateModalAction,
} from './types';

export const setPatients = (patients: IPatient[]): SetPatientAction => ({
  type: SET_PATIENTS,
  payload: patients
});

export const resetPatients = (): PatientsResetAction => ({
  type: RESET
});

export const addPatient = (patient: IPatient): AddPatientAction => ({
  type: ADD_PATIENT,
  payload: patient
});

export const deletePatient = (id: string): DeletePatientAction => ({
  type: DELETE_PATIENT,
  id
});

export const editPatient = (patient: IPatient): EditPatientAction => ({
  type: EDIT_PATIENT,
  payload: patient
});

export const setSelectedPatient = (data: IPatient | null): SetSelectedPatientAction => ({
  type: SET_PATIENT,
  payload: data
});

export const setSelectedPatienVaccinationst = (
  data: IPatient | null
): SetSelectedPatientVaccinationAction => ({
  type: SET_PATIENT_VACCINATIONS,
  payload: data
});

export const setSelectedPatienAllVaccinationsData = (
  data: IPatient | null
): SetSelectedPatientAllVaccinationDataAction => ({
  type: SET_PATIENT_ALL_VACCINATIONS_DATA,
  payload: data
});

export const setSelectedPatienSelectedVaccination = (
  data: IPatient | null
): SetSelectedPatientSelectedVaccinationAction => ({
  type: SET_PATIENT_SELECTED_VACCINATIONS,
  payload: data
});

export const setLoading = (value: boolean): SetLoadingAction => ({
  type: SET_PATIENTS_LOADING,
  payload: value
});

export const showCreateModal = (value: boolean): ShowCreateModalAction => ({
  type: SHOW_CREATE_MODAL,
  payload: value
});

export const showUpdateModal = (value: boolean): ShowUpdateModalAction => ({
  type: SHOW_UPDATE_MODAL,
  payload: value
});

export const setPatientDocumentsList = (data: any): SetSelectedPatientDocumentsListAction => ({
  type: SET_PATIENT_DOCUMENTS_LIST,
  payload: data
});

export const mergePatientDocumentsList = (data: any): MergePatientDocumentsListAction => ({
  type: MERGE_PATIENT_DOCUMENTS_LIST,
  payload: data
});

export const fetchPatients = (url: string) => {
  return (dispatch) => {
    axios
      .get<IPatient[]>(url)
      .then((res) => res.data)
      .then((data) => {
        dispatch(setPatients(data));
      })
      .catch(() => console.error('Server connections error'));
  };
};

export const getPatients = (clinicId: number, page?: number, searchArray?, sortObject?): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    getClinicPatients(clinicId, page, searchArray, sortObject)
      .then((data) => {
        dispatch(setPatients(data));
        dispatch(setLoading(false));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const getPatientDetails = (clinicId: number, id: number): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    getClinicPatientDetails(clinicId, id)
      .then((data) => {
        dispatch(setSelectedPatient(data));
        dispatch(setLoading(false));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const createPatient = (
  clinicId: number,
  data,
  getClientPetsAfterSuccess,
  appointment_id: null,
  callBackAfterCreate?: any
): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    createClinicPatient(clinicId, data)
      .then(() => {
        if (appointment_id) {
          dispatch(getAppointmentDetailsPatients(clinicId, appointment_id));
        }
        if (callBackAfterCreate) {
          callBackAfterCreate();
        } else if (getClientPetsAfterSuccess && data.owner) {
          dispatch(getClientPatients(clinicId, data.owner));
        } else {
          dispatch(getPatients(clinicId, 1));
        }
        dispatch(showCreateModal(false));
        dispatch(setLoading(false));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const updatePatient = (
  clinicId: number,
  data,
  patientId: number,
  appointment_id: null,
  getClientPetsAfterSuccess?: boolean,
  callbackFunc?: () => void
): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    updateClinicPatient(clinicId, data, patientId)
      .then(() => {
        if (appointment_id) {
          dispatch(getAppointmentDetailsPatients(clinicId, appointment_id));
        }
        if (getClientPetsAfterSuccess && data.owner) {
          dispatch(getClientPatients(clinicId, data.owner));
        }
        if (callbackFunc) {
          callbackFunc();
        }
        dispatch(showUpdateModal(false));
        dispatch(getPatientDetails(clinicId, patientId));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const removePatient = (clinicId: number, id: number): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    removeClinicPatient(clinicId, id)
      .then(() => {
        dispatch(setLoading(false));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const setAvatar = (clinicId, patientId, formData, callBackFnc): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    uploadAvatar(clinicId, patientId, formData)
      .then((data) => {
        dispatch(getPatientDetails(clinicId, patientId));
        if (callBackFnc) {
          callBackFnc();
        }
        dispatch(setLoading(false));
      })
      .catch(() => dispatch(setLoading(false)))
      .finally(() => dispatch(setLoading(false)));
  };
};

export const getPatientVaccinations = (clinicId: number, id: number, page: number): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    getClinicPatientVaccinations(clinicId, id, page)
      .then((data) => {
        dispatch(setSelectedPatienVaccinationst(data));
        dispatch(setLoading(false));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const getPatientAllVaccinationsData = (clinicId: number, id: number, page: number): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    getClinicPatientVaccinations(clinicId, id, page)
      .then((data) => {
        dispatch(setSelectedPatienAllVaccinationsData(data));
        dispatch(setLoading(false));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const addPatientVaccination = (clinicId: number, patientId, data: any): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    addClinicPatientVaccination(clinicId, patientId, data)
      .then((data) => {
        dispatch(setSelectedPatienVaccinationst(data));
        dispatch(getPatientVaccinations(clinicId, patientId, 1));
        dispatch(getPatientDetails(clinicId, patientId));
        dispatch(setLoading(false));
        dispatch(getPatientAllVaccinationsData(clinicId, patientId, 0));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const getPatientVaccination = (
  clinicId: number,
  patientId: number,
  vaccinationId: number
): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    getClinicPatientVaccination(clinicId, patientId, vaccinationId)
      .then((data) => {
        dispatch(setSelectedPatienSelectedVaccination(data));
        dispatch(setLoading(false));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const updatePatientVaccination = (
  clinicId: number,
  patientId: number,
  vaccinationId: number,
  data: any
): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    updateClinicPatientVaccination(clinicId, patientId, vaccinationId, data)
      .then(() => {
        dispatch(getPatientVaccinations(clinicId, patientId, 1));
        dispatch(getPatientAllVaccinationsData(clinicId, patientId, 0));
        dispatch(getPatientDetails(clinicId, patientId));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const deletePatientVaccination = (
  clinicId: number,
  patientId: number,
  vaccinationId: number
): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    deleteClinicPatientVaccination(clinicId, patientId, vaccinationId)
      .then(() => {
        dispatch(getPatientVaccinations(clinicId, patientId, 1));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const getPatientDocumentsList = (clinicId: number, patientId: number): any => {
  return (dispatch) => {
    dispatch(setPatientDocumentsList(null));
    dispatch(setLoading(true));
    getClinicPatientDocumentsList(clinicId, patientId)
      .then((data) => {
        dispatch(setPatientDocumentsList(data.reverse()));
        dispatch(setLoading(false));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const createPatientDocument = (clinicId: number, patientId: number, data: any): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    createClinicPatientDocument(clinicId, patientId, data)
      .then((res) => {
        dispatch(getPatientDocumentsList(clinicId, patientId));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};
