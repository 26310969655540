import { Form, FormInstance, Input, Select } from 'antd';
import VLXInputNumber from 'components/VLXInputNumber';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import { IAppState } from 'interfaces/app-state';
import { ILocale } from 'interfaces/locale';
import { ITranferAccount } from 'interfaces/transferAccount';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAccounts, transferAccount } from 'redux/finances/financialAccounts/actions';
import { ShowAccountResponse } from 'services/clinic/accounts/models/ShowAccountResponse';
import { maxRule, requiredRule, validatorRule } from 'utils/form-rules';

const { TextArea } = Input;
const { Option } = Select;

type Props = React.PropsWithChildren<{
  form: FormInstance;
  locale: ILocale;
  currentAccountId: number;
  financialLocale: ILocale;
  callbackFunc: () => void;
}>;

export const TransferAccountsForm = ({
  form,
  children,
  locale,
  currentAccountId,
  financialLocale,
  callbackFunc
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const clinicId = useClinicId();
  const accountsData = useSelector<IAppState, ShowAccountResponse[]>(
    (state) => state.financialAccounts.data
  );
  const currencies = useLocale('private.currencies').labels;

  const [accountIdFrom, setAccountIdFrom] = useState(currentAccountId);
  const [accountIdTo, setAccountIdTo] = useState(null);

  useEffect(() => {
    form.resetFields();
    dispatch(getAccounts(clinicId, 0));
  }, []);

  useEffect(() => {
    if (currentAccountId) form.setFieldValue('account_from_id', currentAccountId);
  }, [currentAccountId]);

  const onSubmit = (data: ITranferAccount) => {
    dispatch(
      transferAccount(clinicId, accountIdFrom, data, callbackFunc)
    );
  };

  const accountFromList = useMemo(
    () =>
      accountsData
        .filter((el) => +el.id !== accountIdTo)
        .map((item) => {
          return (
            <Option key={item.name} title={item.name} value={item.id}>
              {item?.name} ({item?.current_balance} {currencies.uah})
            </Option>
          );
        }),
    [accountsData, accountIdTo]
  );

  const accountToList = useMemo(
    () =>
      accountsData
        .filter((el) => +el.id !== accountIdFrom)
        .map((item) => {
          return (
            <Option key={item.name} title={item.name} value={item.id}>
              {item?.name} ({item?.current_balance} {currencies.uah})
            </Option>
          );
        }),
    [accountsData, accountIdFrom]
  );

  const getMaxAmmount = (): number =>
    accountsData.find((el) => +el.id === accountIdFrom)?.current_balance;

  return (
    <Form onFinish={onSubmit} autoComplete='off' layout='vertical' form={form}>
      <Form.Item
        rules={[requiredRule()]}
        name='account_from_id'
        className='add-equipment-form__multi-select-field'
        label={locale.labels.fromAccount}
      >
        <Select
          style={{ overflow: 'hidden' }}
          filterOption={(input, option) => {
            return option?.title.toString().toLowerCase().includes(input?.toLowerCase());
          }}
          showSearch
          onChange={setAccountIdFrom}
          value={accountIdFrom}
        >
          {accountFromList}
        </Select>
      </Form.Item>
      <Form.Item
        rules={[
          requiredRule(),
          validatorRule((value) => value !== accountIdFrom, financialLocale.errors.accountsAreTheSame)
        ]}
        className='add-equipment-form__multi-select-field'
        label={locale.labels.toAccount}
        name='account_to_id'
      >
        <Select
          style={{ overflow: 'hidden' }}
          onChange={setAccountIdTo}
          filterOption={(input, option) => {
            return option?.title.toString().toLowerCase().includes(input?.toLowerCase());
          }}
          showSearch
        >
          {accountToList}
        </Select>
      </Form.Item>
      <Form.Item
        label={locale.labels.sum}
        rules={[
          requiredRule(),
          validatorRule((value) => value < getMaxAmmount(), financialLocale.errors.summError)
        ]}
        name='amount'
      >
        <VLXInputNumber placeholder='0' type='number' precision={2} step={0.1} />
      </Form.Item>
      <Form.Item
        label={locale.labels.description}
        name='description'
        rules={[maxRule(255, locale.errors.length_rule_255)]}
      >
        <TextArea rows={2} />
      </Form.Item>
      {children}
    </Form>
  );
};
