import { AxiosResponse } from 'axios';
import { ISortObject } from 'services/interfaces/ISortObject';

import baseClient from '../baseClient';
import { generateSearchQuery, sorting } from '../helpers/commonUtils';
import { CreateClinicRequest } from '../interfaces/clinics/create-clinic-request';
import { EditEmployeeRequest } from '../interfaces/clinics/employees/edit-employee-request';
import { RoleCreateRequest } from '../interfaces/clinics/roles/role-create-request';
import { RoleCreateResponse } from '../interfaces/clinics/roles/role-create-response';

const clinicsUrl: string = '/v1/clinics';

export const createClinic = async (clinicData: CreateClinicRequest) =>
  baseClient.post(`${clinicsUrl}/`, clinicData).then((response: any) => {
    return response.data;
  });

export const getClinicDetails = async (id: number) =>
  baseClient.get(`${clinicsUrl}/${id}`).then((response: any) => {
    return response.data;
  });
export const editClinicDetails = async (id: number, data: any) =>
  baseClient.put(`${clinicsUrl}/${id}`, data).then((response: any) => {
    return response.data;
  });

export const getClinicEmployees = async (
  id: number,
  page: number = 1,
  searchValue?: any[],
  itemsCount?: number
) => {
  const itemsPerPage = !!itemsCount && itemsCount !== 0 ? `&items=${itemsCount}` : '';
  return baseClient
    .get(
      `${clinicsUrl}/${id}/employees?page=${page}${itemsPerPage}${generateSearchQuery(searchValue)}`
    )
    .then((response: any) => {
      return response.data;
    });
};

export const getClinicEmployeeById = async (id: number, employeeId: number) =>
  baseClient.get(`${clinicsUrl}/${id}/employees/${employeeId}`).then((response: any) => {
    return response.data;
  });

export const editClinicEmployee = async (
  clinicId: number,
  employeeId: number,
  payload: EditEmployeeRequest
) =>
  baseClient
    .put(`${clinicsUrl}/${clinicId}/employees/${employeeId}`, payload)
    .then((response: any) => {
      return response.data;
    });

export const getClinicInvitations = async (id: number, page: number = 1) =>
  baseClient.get(`${clinicsUrl}/${id}/invitations?page=${page}`).then((response: any) => {
    return response.data;
  });

export const getClinicRoles = async (id: number) =>
  baseClient.get(`${clinicsUrl}/${id}/roles`).then((response: any) => {
    return response.data;
  });

export const getClinicRole = async (clinicId: number, roleId: number) =>
  baseClient.get(`${clinicsUrl}/${clinicId}/roles/${roleId}`).then((response: any) => {
    return response.data;
  });

export const getClinicPermissions = async (id: number) =>
  baseClient.get(`${clinicsUrl}/${id}/permissions`).then((response: any) => {
    return response.data;
  });

export const createClinicRole = async (
  clinicId: number,
  payload: RoleCreateRequest
): Promise<AxiosResponse<RoleCreateResponse>> =>
  baseClient
    .post(`${clinicsUrl}/${clinicId}/roles`, payload)
    .then((response: AxiosResponse<RoleCreateResponse>) => {
      return response;
    });

export const editClinicRole = async (
  clinicId: number,
  roleId: number,
  payload: RoleCreateRequest
): Promise<AxiosResponse<RoleCreateResponse>> =>
  baseClient
    .put(`${clinicsUrl}/${clinicId}/roles/${roleId}`, payload)
    .then((response: AxiosResponse<RoleCreateResponse>) => {
      return response;
    });

export const getClinicRooms = async (id: number, page: number = 1, searchValue?: any[], sortObject?: ISortObject) =>
  baseClient
    .get(`${clinicsUrl}/${id}/rooms?page=${page}${generateSearchQuery(searchValue)}${sorting(sortObject)}`)
    .then((response: any) => {
      return response.data;
    });

export const createClinicRooms = async (id: number, name: string) =>
  baseClient.post(`${clinicsUrl}/${id}/rooms`, name).then((response: any) => {
    return response.data;
  });

export const editClinicRoom = async (id: number, roomId: number, data: any) =>
  baseClient.put(`${clinicsUrl}/${id}/rooms/${roomId}`, data).then((response: any) => {
    return response.data;
  });

export const deleteClinicRoom = async (id: number, roomId: number) =>
  baseClient.delete(`${clinicsUrl}/${id}/rooms/${roomId}`).then((response: any) => {
    return response.data;
  });

export const getClinicAppointments = async (id: number, page: number = 1, searchValue?: any, sortObject?: ISortObject) =>
  baseClient
    .get(`${clinicsUrl}/${id}/appointments/?page=${page}${generateSearchQuery(searchValue)}${sorting(
        sortObject
      )}`)
    .then((response: any) => {
      return response.data;
    });

export const getClinicUserAppointments = async (page: number = 1, searchValue?) =>
  baseClient
    .get(`/v1/appointments/?page=${page}${generateSearchQuery(searchValue)}`)
    .then((response: any) => {
      return response.data;
    });

export const getClinicClients = async (id: number, page: number) =>
  baseClient.get(`${clinicsUrl}/${id}/clients?page=${page}`).then((response: any) => {
    return response.data;
  });

export const getClinicPatients = async (id: string, clientId: string, page: number) =>
  baseClient
    .get(`${clinicsUrl}/${id}/clients/${clientId}/patients?page=${page}`)
    .then((response: any) => {
      return response.data;
    });

export const createClinicEvent = async (data: any, clinicId: number) =>
  baseClient.post(`${clinicsUrl}/${clinicId}/events`, data).then((response: any) => {
    return response.data;
  });

export const editClinicEvent = async (data: any, eventId: number) =>
  baseClient.put(`/v1/events/${eventId}`, data).then((response: any) => {
    return response.data;
  });

export const cancelClinicEvent = async (eventId: number) =>
  baseClient.post(`/v1/events/${eventId}/cancel`).then((response: any) => {
    return response.data;
  });

export const getClinicEmployeeEvents = async (id: number, employeeId: number) =>
  baseClient.get(`${clinicsUrl}/${id}/users/${employeeId}/events`).then((response: any) => {
    return response.data;
  });

export const uploadAvatar = async (clinicId, avatarData) =>
  baseClient
    .post(`/v1/clinics/${clinicId}/avatar`, avatarData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    .then((response: any) => {
      return response.data;
    });

export const getClinicDocumentsList = async (id: number) =>
  baseClient.get(`${clinicsUrl}/${id}/documents`).then((response: any) => {
    return response.data;
  });

export const getClinicDocument = async (id: number, documentId: number) =>
  baseClient.get(`${clinicsUrl}/${id}/documents/${documentId}`).then((response: any) => {
    return response.data;
  });

export const createClinicDocument = async (clinicId: number, data: CreateClinicRequest) =>
  baseClient
    .post(`/v1/clinics/${clinicId}/documents`, data, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    .then((response: any) => {
      return response.data;
    });

export const removeClinicDocument = async (clinicId: number, documentId: number) =>
  baseClient
    .delete(`/v1/clinics/${clinicId}/documents/${documentId}`)
    .then((response: any) => {
      return response.data;
    });
