import './AddAppointmentForm.scss';

import { Button, Col, Form, Input, Row, Select, Typography } from 'antd';
import DateRangePicker from 'components/DateRangePicker';
import PhoneFieldFormItem from 'components/PhoneFieldFormItem';
import animalsList from 'constants/dictionary/animals';
import { locationTypes, roomStatuses } from 'constants/dictionary/default/selectOptions';
import dayjs, { Dayjs } from 'dayjs';
import useClinicId from 'hooks/useClinicId';
import useLocalizedList from 'hooks/useLocalizedList';
import { IAppState } from 'interfaces/app-state';
import { ClientsSelect } from 'layout/components/clientsSelect/ClientsSelect.tsx';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createAppointment } from 'redux/appointments/actions';
import { getClientPatients } from 'redux/clients/actions';
import { getEmployees } from 'redux/employees/actions';
import { getRooms } from 'redux/rooms/actions';
import { getServices } from 'redux/services/actions';
import { patternRule, PATTERNS, requiredRule, validatorRule } from 'utils/form-rules';

import { LocalStorageFilters } from '../../../pages/calendar/types.ts';
import { EmployeesSelect } from '../../components/employeesSelect/EmployeesSelect';
import { RoomsSelect } from '../../components/roomsSelect/RoomsSelect';

const { TextArea } = Input;
const { Option } = Select;

const AddAppointment = ({
  form,
  initialValues,
  children,
  locale,
  calendarView = true,
  searchItemsArray
}: any): JSX.Element => {
  const dispatch = useDispatch();
  const clinicId = useClinicId();
  const localizedRoomStatuses = useLocalizedList(roomStatuses);

  const [createNewClient, setCreateNewClient] = useState(false);
  const [createNewPatient, setCreateNewPatient] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedDoctorId, setSelectedDoctorId] = useState(null);
  const [selectedEmployees, setSelectedEmployees] = useState(null);
  const [locationType, setLocationType] = useState<string>('room');

  const clientPatients = useSelector(({ clients }: IAppState) => clients.clientPatients);
  const employees = useSelector(({ employees }: IAppState) => employees.data);
  const rooms = useSelector(({ rooms }: IAppState) => rooms.data);
  const services = useSelector(({ services }: IAppState) => services.data);

  const onLocationTypeSelect = (value: string): void => {
    setLocationType(value);
  };

  const [startDate, setStartDate] = useState<Dayjs>(dayjs(new Date()));
  const [endDate, setEndDate] = useState<Dayjs>(null);
  const [duration, setDuration] = useState<Dayjs>(null);

  const onPatientSearch = (value: string): void => {
    dispatch(getClientPatients(clinicId, selectedUserId, `[composite][]=${value}`));
  };

  useEffect(() => {
    form.resetFields();
    if (initialValues?.client_id) {
      setSelectedUserId(initialValues.client_id);
    }
  }, []);

  const onSubmit = (data: any): void => {
    const dateTimeFormat: string = 'DD.MM.YYYYTHH:mm';
    data.event.start_time = startDate.tz('utc').format(dateTimeFormat);
    data.event.end_time = endDate.tz('utc').format(dateTimeFormat);
    data.patients = data.patients.filter((el) => el.name);
    data.clinic_id = clinicId;
    delete data.time;
    if (calendarView) {
      const calendarFilters: LocalStorageFilters = JSON.parse(
        localStorage.getItem('eventCalendarFilters')
      );
      const searchFilters = calendarFilters[clinicId]?.filters || [];
      dispatch(createAppointment(clinicId, data, calendarView, searchFilters));
    } else {
      dispatch(createAppointment(clinicId, data, calendarView, searchItemsArray));
    }
  };

  useEffect(() => {
    if (clinicId) {
      dispatch(getRooms(clinicId, 0, [{ name: 'deleted', value: 'false' }]));
      dispatch(getEmployees(clinicId, 0));
      dispatch(getServices(clinicId, 0, [{ name: 'deleted', value: 'false' }]));
    }
  }, [clinicId]);

  useEffect(() => form.resetFields(), []);

  useEffect(() => {
    form.resetFields();
    if (initialValues?.start_time) {
      setStartDate(dayjs(initialValues?.start_time));
      setEndDate(dayjs(initialValues?.end_time));
      setDuration(dayjs(initialValues?.duration));
      form.setFieldValue('date_from', dayjs(initialValues?.start_time));
      form.setFieldValue('time_from', dayjs(initialValues?.start_time));
      form.setFieldValue('end_time', dayjs(initialValues?.end_time));
      form.setFieldValue('duration', dayjs(initialValues?.duration));
    } else {
      form.setFieldValue('date_from', dayjs(new Date()));
      form.setFieldValue('time_from', dayjs(new Date()));
    }
  }, [initialValues]);

  useEffect(() => {
    if (selectedUserId) {
      dispatch(getClientPatients(clinicId, selectedUserId));
    }
  }, [selectedUserId]);

  const patientsList = useMemo(
    () =>
      clientPatients?.map((item) => (
        <Option key={`patient_${item.id}`} value={item.id}>
          {item.name}
        </Option>
      )),
    [clientPatients]
  );

  const employeesList = useMemo(
    () =>
      employees.map(
        (item) =>
          !selectedEmployees?.includes(item.id) && (
            <Option key={item.full_name} title={item.full_name} value={item.id}>
              {item.full_name}
            </Option>
          )
      ),
    [employees, selectedEmployees]
  );

  const getRoomStatus = (stat: string) => {
    const res = localizedRoomStatuses.find((el) => el?.value === stat);
    return res?.label;
  };

  const roomsList = rooms
    ?.filter((room) => !room.not_for_appointment)
    .map((item) => {
      return (
        <Option key={`${item.name}${item.id}`} title={item.name} value={item.id}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{item.name}</span>
            <span style={{ color: 'grey', paddingLeft: 10 }}>{getRoomStatus(item?.status)}</span>
          </div>
        </Option>
      );
    });

  const servicesList = useMemo(
    () =>
      services
        .filter((s) => !s.deleted)
        .map((item) => (
          <Option key={item.name} title={item.name} value={item.id}>
            {item.name}
          </Option>
        )),
    [services]
  );

  const locations = useLocalizedList(locationTypes);
  const localizedAnimalsList = useLocalizedList(animalsList);

  useEffect(() => {
    if (rooms?.length) {
      const roomsForAppoinment = rooms.filter((el) => !el.not_for_appointment);
      if (roomsForAppoinment?.length === 1) {
        const event = form.getFieldValue('event');
        form.setFieldsValue({
          event: { ...event, location: { ...event.location, id: roomsForAppoinment[0].id } }
        });
      }
    }
  }, [roomsList]);

  return (
    <Form
      onFinish={onSubmit}
      autoComplete='off'
      layout='vertical'
      form={form}
      initialValues={initialValues}
      style={{ position: 'relative' }}
    >
      <Row gutter={[20, 10]}>
        <Col span={24} md={12}>
          <Form.Item
            label={locale.labels.visitReason}
            name='title'
            rules={[
              requiredRule(locale.messages.mandatoryField),
              patternRule(PATTERNS.NAME, locale.errors.titleNotValid)
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            className='add-appointment-form__multi-select-field'
            label={locale.labels.service}
            name='services'
          >
            <Select
              mode='multiple'
              filterOption={(input, option) =>
                option?.title.toString().toLowerCase().includes(input?.toLowerCase())
              }
              showSearch
              allowClear
            >
              {servicesList}
            </Select>
          </Form.Item>
          <Form.Item
            className='add-appointment-form__doctor-field'
            label={locale.labels.doctor}
            name='doctor_id'
            rules={[requiredRule(locale.errors.doctorNotValid)]}
          >
            <Select
              onChange={(e) => setSelectedDoctorId(e)}
              filterOption={(input, option) =>
                option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              showSearch
            >
              {employeesList}
            </Select>
          </Form.Item>
          <Form.Item label={locale.labels.attendees} name='attendees'>
            <EmployeesSelect selectedDoctorId={selectedDoctorId} onChange={setSelectedEmployees} />
          </Form.Item>
          <Form.Item label={''}>
            <Form.Item
              name={['event', 'location', 'type']}
              initialValue={'room'}
              label={locale.labels.location}
              rules={[requiredRule(locale.errors.enterLocationType)]}
            >
              <Select filterOption={false} onSelect={onLocationTypeSelect} options={locations} />
            </Form.Item>
            <Form.Item
              name={['event', 'location', 'details']}
              label={locale.labels.details}
              hidden={locationType !== 'other'}
              rules={locationType === 'other' ? [requiredRule(locale.errors.enterTheLocation)] : []}
            >
              <Input />
            </Form.Item>
            <Form.Item
              id='location-room'
              name={['event', 'location', 'id']}
              hidden={locationType !== 'room'}
              className='add-appointment-form__doctor-field appointment-room-select-field'
              label={locale.labels.room}
              rules={locationType === 'room' ? [requiredRule(locale.errors.enterRoom)] : []}
            >
              <RoomsSelect appointment />
            </Form.Item>
          </Form.Item>
        </Col>
        <Col span={24} md={12}>
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            duration={duration}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            setDuration={setDuration}
          />
        </Col>
      </Row>
      <Form.Item label={locale.labels.notes} name={['event', 'notes']}>
        <TextArea rows={5} />
      </Form.Item>
      <Typography.Title level={5}>
        <span style={{ color: '#ff4d4f' }}>*</span>
        {locale.labels.client}
      </Typography.Title>

      {!createNewClient && (
        <Row>
          <Col md={24} span={24}>
            <Form.Item
              className='single-field-select'
              name={['client_id']}
              rules={[requiredRule(locale.errors.ownerNotValid)]}
            >
              <ClientsSelect
                onChange={setSelectedUserId}
                clinicId={clinicId}
                selectedClientId={selectedUserId}
              />
            </Form.Item>
          </Col>
          <Button
            id='addNewClient'
            type={'primary'}
            style={{ borderRadius: 30 }}
            onClick={() => {
              setCreateNewClient(true);
              form.setFieldsValue({ patients_ids: [] });
              setCreateNewPatient(true);
            }}
          >
            {locale.buttons.addNew}
          </Button>
        </Row>
      )}
      {createNewClient && (
        <Row gutter={[20, 10]}>
          <Col span={24} md={12}>
            <Form.Item
              label={locale.labels.firstName}
              name={['client', 'first_name']}
              rules={[requiredRule()]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item label={locale.labels.lastName} name={['client', 'last_name']}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <PhoneFieldFormItem
              formItemPhonePrefix={['client', 'country_code']}
              formItemPhoneNumber={['client', 'phone_number']}
            />
          </Col>
          <Col span={24} md={12}>
            <Form.Item label={locale.labels.email} name={['client', 'email']}>
              <Input />
            </Form.Item>
          </Col>
          <Button
            type={'primary'}
            style={{ borderRadius: 30 }}
            onClick={() => {
              setCreateNewClient(false);
              setCreateNewPatient(false);
              if (!form.getFieldValue('patients').length) {
                form.setFieldValue('patients', [
                  {
                    name: '',
                    species: ''
                  }
                ]);
              }
            }}
          >
            {locale.buttons.chooseExisting}
          </Button>
        </Row>
      )}
      <Typography.Title level={5}>
        <span style={{ color: '#ff4d4f' }}>*</span>
        {locale.labels.patients}
      </Typography.Title>
      <Form.Item name='patients_ids' initialValue={[]} hidden>
        <Input />
      </Form.Item>
      <Form.Item
        name='patients'
        initialValue={[
          {
            name: '',
            species: ''
          }
        ]}
        hidden
      >
        <Input />
      </Form.Item>
      {!createNewClient && (
        <Row>
          <Col md={24} span={24}>
            <Form.Item
              rules={[
                validatorRule(
                  (items) =>
                    !(
                      items.length < 1 &&
                      form.getFieldValue('patients').length &&
                      !form.getFieldValue('patients')[0].name &&
                      !form.getFieldValue('patients')[0].species
                    ),
                  locale.errors.patientNotValid
                )
              ]}
              name={['patients_ids']}
            >
              <Select
                filterOption={false}
                className='patient-select'
                showSearch
                mode='multiple'
                disabled={!selectedUserId}
                onSearch={onPatientSearch}
                placeholder={locale.placeholders.searchPatient}
              >
                {patientsList}
              </Select>
            </Form.Item>
          </Col>
          {!createNewPatient && (
            <Button
              type={'primary'}
              style={{
                borderRadius: 30,
                marginBottom: 10
              }}
              onClick={() => {
                setCreateNewPatient(true);
                if (!form.getFieldValue('patients').length) {
                  form.setFieldValue('patients', [
                    {
                      name: '',
                      species: ''
                    }
                  ]);
                }
              }}
            >
              {locale.buttons.addNew}
            </Button>
          )}
        </Row>
      )}
      {createNewPatient && (
        <Form.List
          name='patients'
          rules={[
            validatorRule(
              (items) =>
                !((!items || items.length < 1) && !form.getFieldValue('patients_ids').length),
              locale.errors.patientNotValid
            )
          ]}
        >
          {(fields, { add, remove }) => (
            <>
              {fields?.map(({ key, name, ...restField }) => (
                <Row key={key} gutter={[20, 10]}>
                  <Col span={22} md={11}>
                    <Form.Item
                      {...restField}
                      name={[name, 'name']}
                      rules={[requiredRule(locale.errors.animalNameNotValid)]}
                    >
                      <Input placeholder={locale.labels.name} />
                    </Form.Item>
                  </Col>
                  <Col
                    span={(createNewClient && key > 0) || !createNewClient ? 22 : 24}
                    md={(createNewClient && key > 0) || !createNewClient ? 11 : 12}
                  >
                    <Form.Item
                      {...restField}
                      name={[name, 'species']}
                      rules={[requiredRule(locale.errors.animalTypeNotValid)]}
                    >
                      <Select filterOption={false} options={localizedAnimalsList} />
                    </Form.Item>
                  </Col>
                  {((createNewClient && key > 0) || !createNewClient) && (
                    <Col span={1} md={1}>
                      <Button
                        onClick={() => {
                          remove(name);
                        }}
                        danger
                        type='primary'
                        shape={'circle'}
                        className={'inventory-update-buttons'}
                      >
                        <span className='icofont icofont-trash' />
                      </Button>
                    </Col>
                  )}
                </Row>
              ))}
              <Row>
                <Form.Item>
                  <Button
                    className='icofont icofont-plus'
                    type={'primary'}
                    style={{
                      borderRadius: 30,
                      fontWeight: 'bold'
                    }}
                    onClick={() => add()}
                  >
                    {locale.buttons.addMore}
                  </Button>
                </Form.Item>
              </Row>
            </>
          )}
        </Form.List>
      )}
      {children}
    </Form>
  );
};

export default AddAppointment;
