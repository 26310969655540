import { Col, Form, Row, Spin, Typography } from 'antd';
import { money } from 'helpers/list-helpers';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import ViewDetailsButtonsGroup from '../../../components/ViewDetailsButtonsGroup';
import {
  financialAccountsTypesOptions,
  financialAcountsOptions,
} from '../../../constants/dictionary/default/selectOptions';
import { showMoreField } from '../../../helpers/ShowMoreData';
import { useLocale } from '../../../hooks/useLocale';
import useLocalizedList from '../../../hooks/useLocalizedList';
import { useUserAccess } from '../../../hooks/useUserAccess';
import { IAppState } from '../../../interfaces/app-state';
import EditFinancialAccount from '../../../layout/modals/finances/addFinancialAccount/EditFinancialAccount';
import { getAccountsDetails, showEditModal } from '../../../redux/finances/financialAccounts/actions';
import { getOpenedShift } from '../../../redux/shifts/actions';
import valueFromList from '../../../utils/value-from-list';
import FinancialAccountsDetailsPayments from './FinancialAccountsDetailsPayments';

const { Title } = Typography;

const FinancialAccountsDetails = () => {
  const dispatch = useDispatch();
  const { clinicId, financialAccountId }: any = useParams();

  const data = useSelector<IAppState, any>((state) => state.financialAccounts.selectedAccount);
  const showAccountsModal = useSelector<IAppState, any>(
    (state) => state.financialAccounts.showEditModal
  );
  const loading = useSelector<IAppState, any>((state) => state.financialAccounts.loading);
  const locale = useLocale('private.finances.financial-accounts');
  const currencies = useLocale('private.currencies').labels;
  const userAccess = useUserAccess();
  const shifts = useSelector<IAppState, any>((state) => state.shifts);
  const openedShift = shifts?.openedShift;
  const [showMoreNotes, setShowMoreNotes] = useState(false);
  const [showMoreDescription, setShowMoreDescription] = useState(false);

  const [editFinancialAccountForm] = Form.useForm();

  useEffect(() => {
    if (clinicId && financialAccountId) dispatch(getAccountsDetails(clinicId, financialAccountId));
  }, [clinicId]);

  useEffect(() => {
    dispatch(getOpenedShift(clinicId));
  }, [clinicId]);

  const localizedFinancialAcountsOptions = useLocalizedList(financialAcountsOptions);
  const localizedFinancialAcountsTypesOptions = useLocalizedList(financialAccountsTypesOptions);

  return (
    <>
      <Row>
        <Col span={24}>
          <Title level={4}>{data?.name}</Title>
        </Col>
      </Row>
      <Spin spinning={loading} className='payments-details-page-spin-container'>
        <div style={{ borderBottom: '1px solid rgba(5, 5, 5, 0.06)' }}>
          <div className='appointment-details-top-container' style={{ marginBottom: 10 }}>
            <Row>
              <Col span={24} md={12} className='appointment-details-top-container__left-column'>
                <Col>
                  {data?.account_number && (
                    <>
                      <span style={{ fontWeight: 600 }}>{locale.labels.number}: </span>
                      <span id='account_number'>{data?.account_number}</span>
                    </>
                  )}
                </Col>
                <Col>
                  {data?.account_type && (
                    <>
                      <span style={{ fontWeight: 600 }}>{locale.labels.accountType}: </span>
                      <span id='account_type'>
                        {valueFromList(localizedFinancialAcountsTypesOptions, data?.account_type)}
                      </span>
                    </>
                  )}
                </Col>
                <Col>
                    <span style={{ fontWeight: 600 }}>{locale.labels.currentBalance}: </span>
                    <span id='current_balance'>
                      {`${money(data?.current_balance)} ${currencies.uah}`}
                    </span>
                </Col>
              </Col>
              <Col span={24} md={12} className='appointment-details-top-container__right-column'>
                <Col>
                  {data?.status && (
                    <>
                      <span style={{ fontWeight: 600 }}>{locale.labels.status}: </span>
                      <span id='status'>
                        {valueFromList(localizedFinancialAcountsOptions, data?.status)}
                      </span>
                    </>
                  )}
                </Col>
                <Col>
                  <span style={{ fontWeight: 600 }}>{locale.labels.description}: </span>
                  <span id='description'>
                    {showMoreField(data?.description, showMoreDescription, setShowMoreDescription)}
                  </span>
                </Col>
                <Col>
                  <span style={{ fontWeight: 600 }}>{locale.labels.notes}: </span>
                  <span id='notes'>
                    {showMoreField(data?.notes, showMoreNotes, setShowMoreNotes)}
                  </span>
                </Col>
              </Col>
            </Row>
          </div>
        </div>
        <div style={{ visibility: !showAccountsModal ? 'visible' : 'hidden', padding: 10 }}>
          <ViewDetailsButtonsGroup
            id={1}
            hideCancelBtn
            fixed
            accessGroup={userAccess.accesses.services}
            editMode={false}
            onEditClick={() => dispatch(showEditModal(true))}
            hideDeleteBtn
          />
        </div>
        <EditFinancialAccount
          form={editFinancialAccountForm}
          locale={locale}
          initialValues={data}
        />
        <FinancialAccountsDetailsPayments
          currentAccountId={data?.id}
          currencies={currencies}
          loading={loading}
          financialAccountId={financialAccountId}
          clinicId={clinicId}
          openedShift={openedShift}
        />
      </Spin>
    </>
  );
};

export default FinancialAccountsDetails;
